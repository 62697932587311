<template>
  <div>
    <v-container class="mt-2" v-if="projects">
      <v-row>
        <v-col col="12" class="mb-10">
          <h1 align="center">Projekte</h1>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col
            v-for="project in projects"
            :key="project.path"
            sm="12" md="6" lg="5" xl="4"
        >
          <v-hover v-slot="{ hover }">
            <project-card :hover="hover" :project="project"/>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import client from '../directus';
import ProjectCard from "@/components/ProjectCard";

export default {
  name: 'Projects',
  components: {ProjectCard},
  props: {
    path: String,
  },
  data() {
    return {
      loading: false,
      page: null,
      error: null,
      header_image: null,
      projects: null,
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.loadPage();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadPage',
  },
  methods: {
    async getProjects() {
      try {
        let result = await client.getItems('project', {
          fields: '*, header_image.*.*, section.*.*',
          sort: '-project_state, name',
          filter: {
            status: {
              eq: "published",
            },
          },
        });
        if (!result) {
          return null;
        }
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async loadPage() {
      this.error = this.page = null;
      this.loading = true;
      try {
        const projects = await this.getProjects();
        if (projects) {
          this.projects = projects;
        } else {
          this.projects = [];
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error;
        console.error('There was an error!', error);
      }
    },
  },
};
</script>

<style scoped>

.done {
  background-color: var(--v-secondary-base);
}

.progress {
  background-color: var(--v-primary-base);
}

.title h2 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 0;
  margin: 0;
  color: white;
  text-align: left;
  letter-spacing: 0.8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
}

</style>